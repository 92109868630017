import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import emailIcon from "./../assets/icons/email.svg";
import logo from "./../assets/icons/logo.svg";
import axios from "axios";
import toast from "react-hot-toast";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please fill in field correctly.");
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error(
        "Invalid email format. Please enter a valid email (e.g., user@example.com)."
      );
      return;
    }

    setloading(true);

    axios
      .post(
        "https://api.contentor.ca/api/v1/account/auth/token/password-reset/",
        {
          email: email.toLowerCase(),
        }
      )
      .then(function (response) {
        setIsSuccess(true);
      })
      .catch(function (error) {
        if (error.status === 500) {
          toast.error("server error");
        }

        toast.error(error.response?.data.message);
      })
      .finally(() => setloading(false));
  };

  return (
    <>
      <header
        className={` sm:px-10 px-4 pt-4 pb-2 flex justify-between items-center sticky top-0 w-full bg-white z-10`}
      >
        <Link to="/" className="flex justify-center items-center gap-1vw">
          <img src={logo} alt="i" />
          <p>CONTENTOR</p>
        </Link>
      </header>

      <div className="w-full flex flex-col gap-y-3 justify-center py-4 h-[85vh] items-center mb-12">
        {isSuccess ? (
          <div className="flex flex-col items-center gap-4 sm:w-[60%] max-w-[400px] w-[90%] text-center">
            <img
              width={50}
              src="/email-red.svg"
              className="mb-4"
              alt="Check Email"
            />
            <p className="text-gray900 font-bold text-2xl sm:text-3xl">
              Check your email
            </p>
            <p className="text-gray-500 text-sm sm:text-base">
              Reset instructions have been sent to your email. Check your inbox
              and spam folder.
            </p>
            <Link
              className="text-center w-full block text-white bg-brand600 p-3 rounded-md mt-6 sm:mt-8"
              to={"/signin"}
            >
              <b>{"<-"} Back to sign in</b>
            </Link>
          </div>
        ) : (
          <div className="flex flex-col gap-4 sm:w-[80%] max-w-[400px] w-[90%]">
            <div className="flex gap-1 flex-col">
              <p className="text-gray900 font-bold text-2xl">
                Forget Password?
              </p>
              <p className=" text-l text-gray-500">
                No worries, we'll send you reset instructions.
              </p>
            </div>

            <form onSubmit={submitHandler} className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <label className="text-gray700 font-bold" htmlFor="name">
                  Email*
                </label>
                <div
                  className={`flex justify-between border-2 border-gray300 outline-none p-3 w-full rounded-xl`}
                >
                  <input
                    className="outline-none"
                    onChange={(e) => setEmail(e.target.value)}
                    id="pass"
                    type="text"
                    placeholder="Enter your email or username"
                  />
                </div>
              </div>
              <button
                disabled={loading}
                className="w-full flex justify-center items-center bg-brand600 py-3 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg font-bold "
                type="submit"
              >
                {loading ? <div className="loader"></div> : "Reset password"}
              </button>
              <Link className="text-center" to={"/signin"}>
                <b className="text-brand600">{"<-"} Back to sign in</b>
              </Link>
            </form>
          </div>
        )}
      </div>
      <footer className="flex w-full justify-between sm:px-8 sm:py-4 p-4 text-gray600 fixed bg-white  bottom-0 ">
        <p>© Contentor.ca</p>
        <div className="flex gap-2">
          <img className="w-5" src={emailIcon} alt="Contact Email" />
          <p>help@example.com</p>
        </div>
      </footer>
    </>
  );
}

export default ForgetPassword;

import React from "react";
import toast from "react-hot-toast";
import image_result from "../../assets/images/background.png";
import { useNavigate } from "react-router-dom";

const Layout = ({ tags, text, img }) => {
  const navigate = useNavigate();

  const handleCopy = async () => {
    const hashtags = tags.map((tag) => `#${tag}`).join(" ");
    const textToCopy = `${text}\n\n${hashtags}`;

    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Copied! 📋");
    } catch {
      toast.error("Failed to copy! ❌");
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = img || image_result;
    link.download = "result-image.png";
    link.click();
    toast.success("Image downloaded! 📥");
  };

  const handleDownloadOnline = async () => {
    try {
      const response = await fetch(img);
      if (!response.ok) throw new Error("Failed to fetch image.");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "generated-image.jpg";
      link.click();
      toast.success("Image downloaded! 📥");
    } catch (error) {
      toast.error("Failed to download the image. ❌");
    }
  };

  const editHandler = () => {
    navigate("/edit-post", {
      state: { img: img || image_result, status: true },
    });
  };

  return (
    <div className="flex flex-col items-center gap-4">
      {/* Header */}
      <h1 className="font-bold text-2xl">Result</h1>
      <p className="text-[#535862] text-center">
        Now you can copy, download, or share your creation ✨
      </p>

      {/* Actions */}
      <div className="flex flex-wrap justify-center gap-4 mt-4">
        {/* Copy Text Button */}
        <button
          onClick={handleCopy}
          className="flex items-center gap-2 px-4 py-2 bg-[#FFF1F3] rounded-lg cursor-pointer hover:bg-[#FFE1E5]"
        >
          <img src="/copy.svg" alt="Copy" className="w-5" />
          <span className="text-[#C01048] font-semibold">Copy Text</span>
        </button>

        {/* Download Image Button */}
        <button
          onClick={img ? handleDownloadOnline : handleDownload}
          className="flex items-center gap-2 px-4 py-2 bg-[#FFF1F3] rounded-lg cursor-pointer hover:bg-[#FFE1E5]"
        >
          <img src="/download.svg" alt="Download" className="w-5" />
          <span className="text-[#C01048] font-semibold">Download Image</span>
        </button>

        {/* Edit Button */}
        <button
          onClick={editHandler}
          className="flex items-center gap-2 px-4 py-2 bg-[#FFF1F3] rounded-lg cursor-pointer hover:bg-[#FFE1E5]"
        >
          <img src="/edit.svg" alt="Edit" className="w-5" />
          <span className="text-[#C01048] font-semibold">Edit</span>
        </button>
      </div>
    </div>
  );
};

export default Layout;

import "./styles/global.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ContactUs from "./pages/contactUs";
import Home from "./pages/Landing_page";
import Error from "./pages/error";
import Header from "./components/header";
import Footer from "./components/footer";
import Signup from "./pages/signup";
import Login from "./pages/signin";
import AboutUs from "./pages/aboutUs";
import Result from "./pages/result";
import Guest_result from "./pages/guest-result";
import Visual_result from "./pages/visual-result";
import Dashboard from "./pages/dashboard";
import Navbar from "./components/navbar_dashborad";
import Setting from "./components/setting";
import Billing from "./components/billing";
import Support from "./components/support";
import Header_dashboard from "./components/header_for_dashboard";
import { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import ForgetPassword from "./pages/forgetPassword";
import EditResult from "./pages/editResult";
import GoogleAuth from "./pages/googleAuth";
import EditPost from "./pages/editPost";
function AppContent() {
  const location = useLocation();
  const [loginStatus, setLoginStatus] = useState("");
  useEffect(() => {
    localStorage.getItem("accessToken")
      ? setLoginStatus(true)
      : setLoginStatus(false);
  });

  const hideHeaderFooter =
    location.pathname === "/signin" ||
    location.pathname === "/forget-password" ||
    location.pathname === "/signup" ||
    location.pathname === "/guest-result" ||
    location.pathname.startsWith("/dashboard");

  if (location.pathname.startsWith("/dashboard") && loginStatus) {
    const isResultPage =
      location.pathname.startsWith("/dashboard/result") ||
      location.pathname.startsWith("/dashboard/visual-result");

    return (
      <>
        <section className="w-full h-[100vh] flex relative">
          <div className="sm:hidden inline-block w-full">
            <Header_dashboard />
          </div>
          {!isResultPage && <Navbar />}
          <section
            className={`${
              isResultPage ? "md:w-full" : "md:w-[77%]"
            }  w-full h-full absolute right-0 md:m-0  mt-14`}
          >
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/setting" element={<Setting />} />
              <Route path="/dashboard/billing" element={<Billing />} />
              <Route path="/dashboard/support" element={<Support />} />
              <Route path="/dashboard/result" element={<Result />} />
              <Route path="/dashboard/result/:id" element={<EditResult />} />
              <Route
                path="/dashboard/visual-result"
                element={<Visual_result />}
              />
            </Routes>
          </section>
        </section>
      </>
    );
  } else {
    return (
      <>
        {hideHeaderFooter ? "" : <Header status="" />}
        <Routes>
          <Route path="/signin/google/*" element={<GoogleAuth />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/guest-result" element={<Guest_result />} />
          <Route path="/edit-post" element={<EditPost />} />
          <Route path="*" element={<Error />} />
          <Route path="/error" element={<Error />} />
        </Routes>
        {hideHeaderFooter ? "" : <Footer status="" />}
      </>
    );
  }
}

function App() {
  return (
    <Router>
      <AppContent />
      <Toaster position="top-center" />
    </Router>
  );
}

export default App;

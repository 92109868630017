import React from "react";
import image_result from "../../assets/images/background.png";

const Tiktok = ({ tags, text, description, img }) => {
  return (
    <div className="w-full max-w-[500px] flex flex-col items-center justify-center p-4 md:pt-16 tiktok-bg">
      <div
        className="w-full sm:w-[90%] md:w-[60%] h-[450px] md:h-[500px] rounded-3xl overflow-hidden shadow-lg"
        style={{
          background: `url(${img ? img : image_result})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col justify-between h-full bg-black bg-opacity-40">
          {/* Top Section */}
          <div>
            <div className="flex justify-between items-center px-6 py-3 text-white text-xs sm:text-sm">
              <span>9:41</span>
              <div className="flex gap-x-2">
                <img
                  src="/social-media/tiktok/sharj.svg"
                  alt="battery"
                  className="w-5 h-5"
                />
                <img
                  src="/social-media/tiktok/wifi.svg"
                  alt="wifi"
                  className="w-5 h-5"
                />
                <img
                  src="/social-media/tiktok/sim.svg"
                  alt="signal"
                  className="w-5 h-5"
                />
              </div>
            </div>
            <div className="flex justify-between items-center px-4 py-2 text-white">
              <img
                src="/social-media/tiktok/live.svg"
                alt="live"
                className="w-5 h-5"
              />
              <div className="flex items-center gap-x-4 font-semibold text-sm">
                <span className="cursor-pointer">Following</span>
                <span className="cursor-pointer">For You</span>
              </div>
              <img
                src="/social-media/tiktok/search.svg"
                alt="search"
                className="w-5 h-5"
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="text-white px-4">
            <div className="mb-2">
              <span className="block font-semibold text-sm sm:text-base mb-1">
                {description}
              </span>
              <p className="text-xs sm:text-sm text-gray-300">{text}</p>
            </div>
            <div className="flex flex-wrap gap-2 text-xs sm:text-sm">
              {tags.map((item, index) => (
                <span
                  key={index}
                  className="bg-gray-800 text-white py-0.5 px-2 rounded-full"
                >
                  #{item}
                </span>
              ))}
            </div>
          </div>

          {/* Bottom Navigation */}
          <div className="flex justify-between items-center bg-black bg-opacity-70 rounded-b-3xl py-4 px-6">
            <div className="flex flex-col items-center gap-y-1">
              <img
                src="/social-media/tiktok/home.svg"
                alt="home"
                className="w-5 h-5"
              />
              <span className="text-[10px] sm:text-xs">Home</span>
            </div>
            <div className="flex flex-col items-center gap-y-1">
              <img
                src="/social-media/tiktok/users.svg"
                alt="friends"
                className="w-5 h-5"
              />
              <span className="text-[10px] sm:text-xs">Friends</span>
            </div>
            <div className="flex flex-col items-center gap-y-1">
              <img
                src="/social-media/tiktok/plus.svg"
                alt="create"
                className="w-7 h-7"
              />
            </div>
            <div className="flex flex-col items-center gap-y-1">
              <img
                src="/social-media/tiktok/chat.svg"
                alt="inbox"
                className="w-5 h-5"
              />
              <span className="text-[10px] sm:text-xs">Inbox</span>
            </div>
            <div className="flex flex-col items-center gap-y-1">
              <img
                src="/social-media/tiktok/user.svg"
                alt="profile"
                className="w-5 h-5"
              />
              <span className="text-[10px] sm:text-xs">Profile</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiktok;

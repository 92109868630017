import React, { useState, useEffect } from "react";
import axios from "axios";
import support_avatar from "./../assets/icons/Support-Avatar.svg";
import phone from "./../assets/icons/phone.svg";

const Suporter = (props) => {
  const createAt = new Date(props.date);
  const date = createAt.toLocaleDateString();
  const time = createAt.toLocaleTimeString();

  return (
    <div className="w-full my-5">
      <div className="flex items-start sm:gap-4 gap-2 ">
        <img src={support_avatar} alt="Support Avatar" />
        <div className="flex flex-col gap-2">
          <small className="text-gray600">Contentor support</small>
          <div className="p-4 rounded-b-lg rounded-tr-lg border-[2px] border-gray-200 bg-gray-50 sm:max-w-[400px] w-full">
            {props.text}
          </div>
          <p className="text-xs text-gray-600">{date} {time}</p>
        </div>
      </div>
    </div>
  );
};

const User = (props) => {
  const createAt = new Date(props.date);
  const date = createAt.toLocaleDateString();
  const time = createAt.toLocaleTimeString();

  return (
    <div className="w-full flex justify-end my-3">
      <div className="flex flex-col gap-1">
        <div className="p-4 rounded-b-lg rounded-tl-lg bg-brand600 text-white max-w-[400px] w-full">
          {props.text}
        </div>
        <p className="text-xs text-gray-600">{date} {time}</p>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div
      className={`p-4 md:px-8 px-4 border-b-[2px] flex justify-between items-center sticky top-0 w-full bg-white z-9`}
    >
      <p className="font-bold text-3xl text text-gray900">Support</p>
      <a
        href="tel:+17037019964"
        className="p-2 items-center flex gap-2 border-[2px] border-gray-300 rounded-lg"
      >
        <img src={phone} alt="Phone" />
        <p className="text-gray-700 font-bold">+1 703-701-9964</p>
      </a>
    </div>
  );
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      let accessToken = localStorage.getItem("accessToken");

      try {
        const response = await axios.get(
          "https://api.contentor.ca/api/v1/support/ticket/",
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
        setMessages(response.data.reverse());
      } catch (error) {
        if (error.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      }
    };

    // Start polling every 5 seconds to get new messages
    const pollingInterval = setInterval(fetchMessages, 5000);

    // Cleanup polling on component unmount
    return () => clearInterval(pollingInterval);
  }, []);

  const handleSendMessage = async () => {
    let accessToken = localStorage.getItem("accessToken");

    if (input !== "") {
      setLoading(true);
      try {
        await axios.post(
          "https://api.contentor.ca/api/v1/support/ticket/",
          { message: input },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
        setInput(""); // Clear input after sending the message
        setLoading(false);
      } catch (error) {
        if (error.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
        setLoading(false);
      }
    }
  };

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <div className="">
        <Header />
        <div className="sm:p-5 p-2 mb-20">
          {messages.map((msg, index) => (
            <div key={index} className="flex flex-col items-end">
              <User text={msg.message} date={msg.created_at} />
              {msg.ticket_reply &&
                msg.ticket_reply.map((reply, index) => (
                  <Suporter
                    key={index}
                    text={reply.message}
                    date={reply.created_at}
                  />
                ))}
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center relative">
          <div className="flex bg-white p-3 border-[2px] border-gray-200 rounded-xl fixed sm:w-[70%] w-full bottom-0">
            <input
              onKeyDown={handleKeydown}
              onChange={(e) => setInput(e.target.value)}
              className="w-full outline-none"
              placeholder="Send a message"
              value={input}
              type="text"
            />
            <button
              disabled={loading}
              onClick={handleSendMessage}
              className="px-4 py-2 bg-brand700 rounded-lg text-white"
            >
              {loading ? <div className="loader"></div> : "Send"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;

import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const EditResult = () => {
  const { id } = useParams();
  let accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    axios
      .get(`http://api.contentor.ca/api/v1/content/edit-text-content/${id}/`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => console.log(res));
  }, []);

  return <div>{id}</div>;
};

export default EditResult;

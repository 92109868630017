import Features from "../components/features";
import Pricing from "../components/pricing";
import Question from "../components/question";
import TrendContent from "../components/trend_content";
import WhyChooseUs from "../components/why_choose_us";
import Creator from "../components/creator";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
const Section = ({ children, id }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`transition duration-1000 ${
        isVisible ? "opacity-100 translate-y-[80px]" : "opacity-0"
      }`}
    >
      {children}
    </div>
  );
};

function Home() {
  const location = useLocation();
  const [why, setWhy] = useState(false);
  const [features, setFeatures] = useState(false);
  const [pricing, setPricing] = useState(false);
  const [Home, setHome] = useState(false);
  const [faq, setFaq] = useState(false);
  useEffect(() => {
    if (location.hash === "#why_choose_us") {
      setWhy(true);
    } else {
      setWhy(false);
    }

    if (location.hash === "#features") {
      setFeatures(true);
    } else {
      setFeatures(false);
    }

    if (location.hash === "#pricing") {
      setPricing(true);
    } else {
      setPricing(false);
    }
    if (location.hash === "") {
      setHome(true);
    } else {
      setHome(false);
    }
    if (location.hash === "#faq") {
      setFaq(true);
    } else {
      setFaq(false);
    }
  });
  const [tag, setTag] = useState("");
  
  return (
    <div>
      <Section id="home">
        <Creator scroll={Home} setTag={setTag} tag={tag} />
      </Section>
      <Section id="trend">
        <TrendContent setTag={setTag} />
      </Section>
      <Section id="why_choose_us">
        <WhyChooseUs scroll={why} />
      </Section>
      <Section id="features">
        <Features scroll={features} />
      </Section>
      <Section id="pricing">
        <Pricing scroll={pricing} />
      </Section>
      <Section id="faq">
        <Question scroll={faq} />
      </Section>
    </div>
  );
}
export default Home;

import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import FilerobotImageEditor, { TABS, TOOLS } from "react-filerobot-image-editor";

const EditPost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resultData = location?.state;

  const [isLoading, setIsLoading] = useState(true);

  // Handle error redirection if resultData is invalid
  useEffect(() => {
    if (!resultData?.status) {
      navigate("/error");
    }
  }, [resultData]);

  // Load fonts dynamically
  const loadFonts = () => {
    const link = document.createElement("link");
    link.href = "https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Lato&family=Montserrat&family=Arial&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    link.onload = () => setIsLoading(false);
  };

  useEffect(() => {
    loadFonts();
  }, []);

  // Custom Fonts
  const customFonts = [
    "Arial", "Helvetica", "Times New Roman", "Tahoma",
    "Courier New", "Comic Sans MS", "Georgia", "Verdana",
    "Roboto", "Open Sans", "Lato", "Montserrat"
  ];

  // Handle save action
  const handleSave = (imageInfo, designState) => {
    console.log("Image saved", imageInfo, designState);
    const tmpLink = document.createElement("a");
    tmpLink.download = imageInfo.fullName;
    tmpLink.href = imageInfo.imageBase64;
    tmpLink.style = "position: absolute; z-index: -111; visibility: none;";
    document.body.appendChild(tmpLink);
    tmpLink.click();
    document.body.removeChild(tmpLink);
  };

  return (
    <div>
      <div className="h-[75vh]">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <span>Loading fonts...</span>
          </div>
        ) : (
          <FilerobotImageEditor
            source={
              resultData?.img ||
              "https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
            }
            onSave={handleSave}
            annotationsCommon={{
              fill: "#000000", // Example fill color for annotations
            }}
            Text={{
              text: "Filerobot...", // Default text
              fonts: customFonts, // Custom font families
              fontSize: 36,
              color: "#000000",
            }}
            Rotate={{
              angle: 90, // Default angle
              componentType: "slider", // Use a slider for rotation control
            }}
            tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // Tabs to display
            defaultTabId={TABS.ANNOTATE} // Set default tab
            defaultToolId={TOOLS.TEXT} // Default tool to use (Text)
          />
        )}
      </div>
    </div>
  );
};

export default EditPost;

import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import logo from "./../assets/icons/logo.svg";
import dashboard from "./../assets/icons/dashboard.svg";
import billing from "./../assets/icons/billing.svg";
import setting from "./../assets/icons/setting.svg";
import support from "./../assets/icons/support.svg";
import seke from "./../assets/icons/seke.svg";
import exit from "./../assets/icons/exit.svg";
import IMG from "./profile_image";

function Main() {
  const [username, setUsename] = useState("");
  const [email, setEmail] = useState("");

  function isTokenValid(token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = payload.exp * 1000;
      return Date.now() < expirationTime;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");

    axios
      .get(`https://api.contentor.ca/api/v1/account/profile/`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        setUsename(response.data.results[0].user);
        setEmail(response.data.results[0].email);
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });
  });

  return (
    <>
      <nav className="w-[23%] h-[100vh] md:flex hidden flex-col justify-between border-r-2 border-gray200 fixed z-10 ">
        <div className="pl-7">
          <div>
            <Link to="/" className="flex py-5 items-center gap-1vw">
              <img src={logo} alt="i" />
              <p>CONTENTOR</p>
            </Link>
          </div>
          <div className="flex flex-col gap-4 mt-3">
            <Link to="/dashboard">
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={dashboard} /> <p>Dashboard</p>
              </div>
            </Link>
            <Link to="dashboard/support">
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={support} /> <p>Support</p>
              </div>
            </Link>
            <Link to="dashboard/billing">
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={billing} /> <p>Billing</p>
              </div>
            </Link>
            <Link to="dashboard/setting">
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={setting} /> <p>Setting</p>
              </div>
            </Link>
          </div>
        </div>

        <div>
          <div className="w-full flex justify-center">
            <hr className="w-[80%] content-center"></hr>
          </div>

          <div className="flex justify-between items-center px-4 py-6">
            <div className="flex gap-4">
              <IMG size={40} />
              <div>
                <p className=" text-gray900 font-bold">{username}</p>
                <small className="text-gray600">{email}</small>
              </div>
            </div>
            <img
              className=" cursor-pointer"
              onClick={logout_handel}
              src={exit}
            />
          </div>
        </div>
      </nav>
    </>
  );
  function logout_handel() {
    localStorage.removeItem("accessToken");
    window.location.replace("/");
  }
}
export default Main;

import React, { useEffect, useState } from "react";
import upload from "./../assets/icons/upload.svg";
import axios from "axios";
import toast from "react-hot-toast";

function Main() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [gender, setGender] = useState("-----");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  let accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(
          "https://api.contentor.ca/api/v1/account/profile/",
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );

        if (res.data && res.data.results) {
          const userProfile = res.data.results[0];
          setData(userProfile);
          setGender(userProfile.gender || "-----");
          setPreview(
            userProfile.avatar ? fixImageUrl(userProfile.avatar) : upload
          );
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      }
    };

    getData();
  }, []);

  const fixImageUrl = (url) => {
    return url.startsWith("http://") ? url.replace("http://", "https://") : url;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setError("File size exceeds 2MB limit.");
        return;
      }
      setFile(file);
      setPreview(URL.createObjectURL(file));
      setError("");
    }
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();

      if (file) {
        formData.append("avatar", file);
      }
      if (data.first_name) formData.append("first_name", data.first_name);
      if (data.last_name) formData.append("last_name", data.last_name);
      if (gender !== "-----") formData.append("gender", gender);
      if (data.birthday) formData.append("birthday", data.birthday);

      if (formData.entries().next().done) {
        setError("No changes to save.");
        return;
      }

      await axios.put(
        `https://api.contentor.ca/api/v1/account/profile/${data.user}/`,
        formData,
        {
          headers: {
            Authorization: accessToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Profile updated successfully!");
    } catch (err) {
      setError("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!password || !passwordConf) {
      toast.error("Please fill in all fields correctly.");
      return;
    }

    if (password !== passwordConf) {
      toast.error("Passwords do not match!");
      return;
    }

    setLoading1(true);

    try {
      await axios.post(
        "https://api.contentor.ca/api/v1/account/auth/token/change-password/",
        { new_password: password },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setPassword("");
      setPasswordConf("");
      toast.success("Password changed successfully!");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "You are limited, try again later."
      );
    } finally {
      setLoading1(false);
    }
  };

  return (
    <section className="p-4">
      <p className="font-bold text-3xl text-gray-900">Settings</p>
      <div className="flex flex-col md:flex-row gap-6 justify-between mt-10 w-full">
        <div className="w-full md:w-1/3">
          <p className="text-gray-700 font-medium">Personal Info</p>
          <small className="text-gray-600">
            Update your photo and personal details.
          </small>
        </div>

        <form
          onSubmit={handleSaveChanges}
          className="shadow-md rounded-xl border border-gray-200 w-full md:w-2/3 p-6 bg-white"
        >
          <div className="flex flex-wrap gap-5">
            <div className="w-full md:w-[45%]">
              <label htmlFor="first-name" className="text-gray-700">
                First Name
              </label>
              <input
                id="first-name"
                type="text"
                className="p-2 border border-gray-300 rounded-lg outline-none w-full"
                placeholder="First Name"
                value={data.first_name || ""}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, first_name: e.target.value }))
                }
              />
            </div>

            <div className="w-full md:w-[45%]">
              <label htmlFor="last-name" className="text-gray-700">
                Last Name
              </label>
              <input
                id="last-name"
                type="text"
                className="p-2 border border-gray-300 rounded-lg outline-none w-full"
                placeholder="Last Name"
                value={data.last_name || ""}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, last_name: e.target.value }))
                }
              />
            </div>

            <div className="w-full md:w-[45%]">
              <label htmlFor="gender" className="text-gray-700">
                Gender
              </label>
              <select
                id="gender"
                value={gender}
                onChange={handleGenderChange}
                className="p-3 border border-gray-300 rounded-lg w-full bg-white"
              >
                <option value="-----">-----</option>
                <option value="mrs">Female</option>
                <option value="mr">Male</option>
              </select>
            </div>

            <div className="w-full md:w-[45%]">
              <label htmlFor="birthday" className="text-gray-700">
                Birthday
              </label>
              <input
                id="birthday"
                type="date"
                className="p-2 border border-gray-300 rounded-lg outline-none w-full"
                value={data.birthday || ""}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, birthday: e.target.value }))
                }
              />
            </div>
          </div>

          <div className="flex items-start gap-3 mt-3">
            <div>
              <img
                src={preview || upload}
                alt="Upload preview"
                className="w-20 h-20 object-cover rounded-full"
              />
            </div>
            <div className="w-[80%] p-5 border-2 border-dashed rounded-lg flex flex-col items-center">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
                id="upload-input"
              />
              <label
                htmlFor="upload-input"
                className="cursor-pointer flex flex-col items-center"
              >
                <img src="/upload.svg" alt="upload" />
                <small className="text-gray-600 mt-2">
                  <b className="text-red-600">Click to upload</b> or drag & drop
                  (PNG/JPG, max 2MB)
                </small>
              </label>
            </div>
            {error && <small className="text-red-600">{error}</small>}
          </div>

          <div className="flex justify-end gap-x-4 mt-6">
            <button
              className="border-2 text-black px-6 py-2 rounded-lg"
              type="button"
              onClick={() => (window.location.href = "/dashboard")}
            >
              Cancel
            </button>
            <button
              className="bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700 disabled:opacity-50"
              type="submit"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>

      <div className="flex flex-col md:flex-row gap-6 justify-between mt-10 w-full">
        <div className="w-full md:w-1/3">
          <p className="text-gray-700 font-medium">Account Info</p>
          <small className="text-gray-600">Update your password.</small>
        </div>

        <form
          onSubmit={submitHandler}
          className="shadow-md rounded-xl border border-gray-200 w-full md:w-2/3 p-6"
        >
          <div className="flex flex-wrap gap-3">
            <div className="w-full">
              <label htmlFor="email" className="text-gray-700">
                Email
              </label>
              <div className="flex items-center border border-gray-300 p-2 rounded-lg bg-gray-100">
                <img width={18} src="/email.svg" alt="email" />
                <input
                  id="email"
                  type="text"
                  disabled
                  className="outline-none w-full px-2 text-gray-400 bg-gray-100 cursor-not-allowed"
                  placeholder="olivia@untitledui.com"
                  value={data.email}
                />
              </div>
            </div>

            <div className="w-full md:w-[49%]">
              <label htmlFor="password" className="text-gray-700">
                Password
              </label>
              <input
                id="password"
                type="password"
                className="p-2 border border-gray-300 rounded-lg outline-none w-full"
                placeholder="New password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="w-full md:w-[49%]">
              <label htmlFor="passwordConf" className="text-gray-700">
                Confirm
              </label>
              <input
                id="passwordConf"
                type="password"
                className="p-2 border border-gray-300 rounded-lg outline-none w-full"
                placeholder="Confirm New password"
                value={passwordConf}
                onChange={(e) => setPasswordConf(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-end gap-x-4 mt-6">
            <button
              className="border-2 text-black px-6 py-2 rounded-lg"
              type="button"
              onClick={() => (window.location.href = "/dashboard")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700 disabled:opacity-50"
              disabled={loading1}
            >
              {loading1 ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Main;

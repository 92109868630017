import React, { useState } from "react";
import image_contentor from "./../assets/images/Image.png";
import toast from "react-hot-toast";

function ContactUs() {
  // State برای ذخیره مقادیر فرم
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);

  // Handler برای ارسال درخواست
  const handleSubmit = async (e) => {
    e.preventDefault();
    const phoneRegex = /^\+98\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // اعتبارسنجی داده‌ها
    if (!fullName || !email || !phoneNumber || !message || message.length < 5) {
      toast.error("Please fill in all fields correctly.");
      return;
    }

    if (!phoneRegex.test(phoneNumber)) {
      toast.error(
        "Invalid phone number format. Please enter in +989XXXXXXXXX format."
      );
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error(
        "Invalid email format. Please enter a valid email (e.g., user@example.com)."
      );
      return;
    }

    setAgreed(true);

    const contactData = {
      full_name: fullName,
      email: email,
      phone_number: phoneNumber,
      message: message,
    };

    try {
      const response = await fetch(
        "https://api.contentor.ca/api/v1/support/contact-us/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactData),
        }
      );

      const result = await response.json();
      if (!response.ok) {
        toast.error(result.detail);
      } else {
        toast.success("Message sent successfully!");
        // Clear form after successful submission
        setFullName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    } finally {
      setAgreed(false);
    }
  };

  return (
    <div className="w-full flex flex-row justify-center items-center p-9 gap-x-3vw">
      <div className="flex flex-col gap-y-5 w-full sm:w-[40vw]">
        <div>
          <p className="text-gray900 font-black sm:text-3xl text-4xl">
            Get in touch
          </p>
          <p className="text-gray600">
            Our friendly team would love to hear from you.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-[15px]">
          <div className="flex flex-col items-center justify-center sm:flex-row gap-x-2vw ">
            <div className="w-full">
              <p className="text-gray700">Name</p>
              <input
                className="w-full p-3 border-2 border-gray300 rounded-lg outline-none"
                placeholder="Full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className="w-full">
              <p className="text-gray700">Email</p>
              <input
                className="w-full p-3 border-2 border-gray300 rounded-lg outline-none"
                placeholder="you@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="">
            <p className="text-gray700">Phone number</p>
            <input
              className="p-3 border-2 border-gray300 rounded-lg outline-none w-full"
              placeholder="+989XXXXXXXXX"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div>
            <p className="text-gray700">Message</p>
            <textarea
              className="h-[120px] p-3 border-2 border-gray-200 rounded-lg outline-none w-full resize-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-brand600 py-2 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg"
              disabled={agreed}
            >
              Send message
            </button>
          </div>
        </form>
      </div>
      <img
        src={image_contentor}
        alt="contactUs_image"
        className="h-[30vw] hidden sm:inline-block"
      />
    </div>
  );
}

export default ContactUs;

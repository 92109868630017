import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useEffect, useState } from "react";
import axios from "axios";
import countryList from "./api.json";

function Main(props) {
  const [trends_list, setTrends_list] = useState([]);
  const [result, setResult] = useState([]);
  const [isLoaded, setIsloaded] = useState(false);
  const [selected, setSelected] = useState(0);
  const [eachDay, setEachDay] = useState("");

  // Fetch worldwide public holidays on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://date.nager.at/api/v3/NextPublicHolidaysWorldWide"
        );
        setResult(res.data);
        const date = new Date(res.data[0].date);
        const dayOfMonth = date.getDate();
        setEachDay(dayOfMonth);
        getEachHoliday();
      } catch (error) {
        setResult([]); // Handle API failure with an empty list
      }
    };
    fetchData();
  }, []);

  // Fetch specific holiday data for a country
  const getEachHoliday = async () => {
    setIsloaded(true);
    try {
      const res = await axios.get(
        `https://date.nager.at/api/v3/NextPublicHolidaysWorldWide`
      );
      setTrends_list(res.data);
    } catch (error) {
    } finally {
      setIsloaded(false);
    }
  };
  function select_controler(index) {
    setSelected(index);
  }

  return (
    <>
      {/* Mobile divider */}
      <div className="w-full md:hidden inline-block justify-center">
        <div className="sm:w-[80%] z-50 w-[90%] h-[1.5px] bg-gray200 mt-10 content-center"></div>
      </div>

      {/* Title section */}
      <div
        id="trends"
        className="w-full sm:px-20 px-4 flex justify-between items-center mt-20 sm:mt-40"
      >
        <p className="font-bold sm:text-2xl text-gray900">
          Trend content of day
        </p>
      </div>

      {/* Desktop view */}
      <div className="w-full sm:flex hidden sm:px-20 justify-center gap-8 mt-10">
        {result.length === 0 ? (
          <p>Loading public holidays...</p>
        ) : (
          result
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (i) =>
                    new Date(i.date).getDate() === new Date(item.date).getDate()
                )
            )
            .map((item, index) => {
              const date = new Date(item.date);
              const dayOfWeek = date.toLocaleDateString("en-US", {
                weekday: "long",
              });
              const dayOfMonth = date.getDate();
              const month = date.toLocaleDateString("en-US", { month: "long" });
              // const year = date.getFullYear();
              return (
                <div
                  key={index}
                  onClick={() => {
                    select_controler(index);
                    getEachHoliday();
                    setEachDay(dayOfMonth);
                  }}
                  className={` ${
                    selected === index
                      ? "gradient text-brand50"
                      : "bg-brand50 text-brand900"
                  } cursor-pointer w-[180px] shadow-lg flex flex-wrap text-center flex-col gap-4 px-8 py-8 rounded-2xl duration-300 transition transform hover:-translate-y-[2px]`}
                >
                  <p className="font-bold text-lg">{dayOfMonth}</p>
                  <p className="font-bold text-lg">{month}</p>
                  <p>{dayOfWeek}</p>
                </div>
              );
            })
        )}
      </div>

      {/* Mobile view with Swiper */}
      <div className="w-full md:hidden flex px-5 justify-center gap-8 mt-10">
        <Swiper
          modules={[Pagination, Autoplay, Scrollbar, A11y]}
          slidesPerView={2.2}
          observer={true}
          observeParents={true}
          className="h-[200px]"
        >
          {result.length === 0 ? (
            <p>Loading public holidays...</p>
          ) : (
            result
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (i) =>
                      new Date(i.date).getDate() ===
                      new Date(item.date).getDate()
                  )
              )
              .map((item, index) => {
                const date = new Date(item.date);
                const dayOfWeek = date.toLocaleDateString("en-US", {
                  weekday: "long",
                });
                const dayOfMonth = date.getDate();
                const month = date.toLocaleDateString("en-US", {
                  month: "long",
                });
                return (
                  <SwiperSlide key={index}>
                    <div
                      onClick={() => {
                        select_controler(index);
                        getEachHoliday();
                        setEachDay(dayOfMonth);
                      }}
                      className={`${
                        selected === index
                          ? "gradient text-brand50"
                          : "bg-brand50 text-brand900"
                      } cursor-pointer w-[90%] shadow-lg flex text-center flex-col gap-4 px-8 py-8 rounded-2xl duration-300 transition`}
                    >
                      <p className="font-bold text-xl">{dayOfMonth}</p>
                      <p className="font-bold text-xl">{month}</p>
                      <p>{dayOfWeek}</p>
                    </div>
                  </SwiperSlide>
                );
              })
          )}
        </Swiper>
      </div>

      {/* Trends list */}
      <div className="flex flex-col gap-8 justify-center items-center mt-40">
        {isLoaded ? (
          <p>Loading trends...</p>
        ) : (
          trends_list
            .filter((i) => new Date(i.date).getDate() === eachDay)
            .map((item, index) => {
              const countryCode = item.countryCode;
              const countryName = countryList.find(
                (item) => item.countryCode === countryCode
              ).name;
              return (
                <div
                  key={index}
                  className="flex w-full sm:w-[90%] justify-between items-center sm:px-[9vw] px-4"
                >
                  <div className="flex items-center gap-5 sm:w-[60%]">
                    <p className="flex justify-center items-center bg-brand50 text-brand600 w-[20px] h-[20px] p-4 rounded-md font-bold">
                      {index + 1}
                    </p>
                    <div className="flex flex-col gap-3 text-gray600">
                      <b className="flex justify-between items-center">
                        {item.name}
                      </b>
                      <p className="">{item.localName}</p>
                      <p>{countryName}</p>
                    </div>
                  </div>
                  <a
                    href="#trend"
                    onClick={() => props.setTag(item.name)}
                    className="w-[80px] h-[40px] font-bold flex justify-center items-center text-white bg-brand600 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px]"
                  >
                    <button>Use</button>
                  </a>
                </div>
              );
            })
        )}
      </div>
    </>
  );
}

export default Main;

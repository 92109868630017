import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "./../assets/icons/logo.svg";
import close from "./../assets/icons/close.svg";
import menu from "./../assets/icons/menu.svg";
import dashboard from "./../assets/icons/dashboard.svg";
import billing from "./../assets/icons/billing.svg";
import setting from "./../assets/icons/setting.svg";
import support from "./../assets/icons/support.svg";
import exit from "./../assets/icons/exit.svg";
import axios from "axios";
import IMG from "./profile_image";

function Header() {
  const [username, setUsename] = useState("");

  function isTokenValid(token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = payload.exp * 1000;

      return Date.now() < expirationTime;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");

    axios
      .get(`https://api.contentor.ca/api/v1/account/profile/`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        setUsename(response.data.results[0].user);
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });
  });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={`${
          !isOpen ? "flex" : "hidden"
        } px-7vw shadow pt-4 pb-2 flex justify-between items-center sticky top-0 w-full bg-white z-10`}
      >
        <div className="flex justify-center items-center gap-3vw">
          <Link to="/" className="flex justify-center items-center gap-1vw">
            <img src={logo} alt="i" />
            <p>CONTENTOR</p>
          </Link>

          <ul className=" hidden md:flex gap-2vw text-gray600"></ul>
        </div>
        <Link
          className="hidden md:flex justify-center items-center text-white bg-brand600 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px] "
          alt="get started"
          to="/signin"
        >
          <button>Get started</button>
        </Link>
        <button
          onClick={() => {
            control_menu();
          }}
          className=" md:hidden inline-block"
        >
          <img src={menu} alt="menu" />
        </button>
      </div>
      {/* for mobile */}
      <div
        className={`h-[100vh] ${
          isOpen ? "flex" : "hidden"
        } top-0 right-0 fixed z-30 bg-white w-full`}
      >
        <div
          className={` w-full h-[95vh] px-4 py-4 flex flex-col justify-between`}
        >
          <div className="flex justify-between items-center">
            <Link to="/" className="flex justify-center items-center gap-1vw">
              <img src={logo} alt="" />
              <p>CONTENTOR</p>
            </Link>
            <button
              onClick={() => {
                control_menu();
              }}
            >
              <img src={close} />
            </button>
          </div>
          <div className="flex flex-col gap-8 mt-10">
            <Link
              onClick={() => {
                control_menu();
              }}
              to="dashboard/"
            >
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={dashboard} /> <p>Dashboard</p>
              </div>
            </Link>
            <Link
              onClick={() => {
                control_menu();
              }}
              to="dashboard/support"
            >
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={support} /> <p>Support</p>
              </div>
            </Link>
            <Link
              onClick={() => {
                control_menu();
              }}
              to="dashboard/billing"
            >
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={billing} /> <p>Billing</p>
              </div>
            </Link>
            <Link
              onClick={() => {
                control_menu();
              }}
              to="dashboard/setting"
            >
              <div className="flex gap-3 text-gray-700 font-bold cursor-pointer">
                <img src={setting} /> <p>Setting</p>
              </div>
            </Link>
          </div>

          <div className="flex justify-between items-center px-4 py-6">
            <div className="flex gap-4">
              <IMG size={40} />

              <div>
                <p className=" text-gray900 font-bold">{username}</p>
                <small className="text-gray600">olivia@untitledui.com</small>
              </div>
            </div>
            <img
              className=" cursor-pointer"
              onClick={logout_handel}
              src={exit}
            />
          </div>
        </div>
      </div>
    </>
  );
  function control_menu() {
    if (!isOpen) {
      setIsOpen(true);
      document.body.style.overflow = "hidden";
    } else {
      setIsOpen(false);
      document.body.style.overflow = "auto";
    }
  }
  function logout_handel() {
    localStorage.removeItem("accessToken");
    window.location.replace("/");
  }
}

export default Header;

import React, { useEffect, useState } from "react";

const SuccesComponent = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
 
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        onClose();
      }
    }, 5000); 

   
    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null;

  return (
    <div
      style={{
        margin: "0 auto",
      }}
    >
      <div
        style={{
          padding: "10px",
          margin: "100px 10px",
          backgroundColor: "#f8d7da",
          color: "#237024",
          border: "1px solid #f5c6cb",
          borderRadius: "5px",
          zIndex: "10",
          position: "absolute",
        }}
      >
        <strong>Message:</strong> {message}
      </div>
    </div>
  );
};

export default SuccesComponent;

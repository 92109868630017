import { Link } from "react-router-dom";
import backIcon from "./../assets/icons/back.svg";
function Main() {
  return (
    <>
      <section className="w-full md:h-[70vh] flex items-center p-12 md:px-40">
        <div className="flex flex-col gap-5">
          <small className="text-brand700">404 error</small>
          <h1 className="text-gray-800 text-4xl font-bold">
            We can’t find that page
          </h1>
          <small className="text-gray600">
            Sorry, the page you are looking for doesn't exist or has been moved.
          </small>
          <div className="flex gap-4 md:flex-row flex-col-reverse">
            <button
              onClick={() => window.history.back()}
              className=" flex justify-center items-center text-gray700 bg-white border-[2px] border-gray200 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px] gap-3 "
            >
              <img src={backIcon} /> Go back
            </button>
            <Link to="/">
              <button className="flex justify-center items-center sm:w-auto w-full text-white bg-brand600 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px] gap-3">
                Take me home
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
export default Main;

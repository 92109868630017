import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import search from "./../assets/icons/searchIcon.svg";
import link from "./../assets/icons/linkIcon.svg";
import image_result from "./../assets/images/result.png";
import copy from "./../assets/icons/copy2.svg";
import remove from "./../assets/icons/delete2.svg";
import download from "./../assets/icons/download2.svg";
import closeIcon from "./../assets/icons/close.svg";
import free from "./../assets/icons/free.svg";
import permium from "./../assets/icons/permium.svg";
import axios from "axios";
import ErrorComponent from "../components/error";
import toast from "react-hot-toast";
import AsyncSelect from "react-select/async";
import Select from "react-select";

function Results() {
  const [error, setError] = useState(null);
  let accessToken = localStorage.getItem("accessToken");

  const [userName, setuserName] = useState("");
  const [searchValue, set_searchValue] = useState(" ");
  const [social_media, setsSocial_media] = useState("");
  const [idea, setIdea] = useState("");
  const [result_list, set_resultList] = useState([]);
  const [result_list_visual, set_resultList_visual] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [visualAccont, set_visualAccont] = useState(false);
  const [loading_remove, setLoading_remove] = useState(null);
  const [trends, setTrends] = useState([]);
  const [loadingTrends, setLoadingTrends] = useState(false);
  const [loading_remove_visual, setLoading_remove_visual] = useState(null);
  const [nextVisualContent, setNextVisualContent] = useState();
  const [prevVisualContent, setPrevVisualContent] = useState();
  const [isNextVisualContent, setIsNextVisualContent] = useState(true);
  const [isPrevVisualContent, setIsPrevVisualContent] = useState(true);
  const [taxtType, setTaxtType] = useState(
    "Write your idea here for generating content, or use the trends below."
  );

  const [nextResult, setNextResult] = useState();
  const [prevResult, setPrevResult] = useState();
  const [isNextResult, setIsNextResult] = useState(true);
  const [isPrevResult, setIsPrevResult] = useState(true);
  const [languageId, setLanguageId] = useState();
  const ITEMS_PER_PAGE = 5;
  const [placeholder, setPlaceholder] = useState("Languages");
  const [selectedOption, setSelectedOption] = useState(null);
  const [opt, setOpt] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchOptions = async (inputValue) => {
    try {
      const response = await axios.get(
        "https://api.contentor.ca/api/v1/content/language/"
      );

      const filteredOptions = response.data
        .filter((item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => ({
          label: item.name,
          value: item.id,
        }));

      return filteredOptions;
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setCurrentItems(trends.slice(startIndex, endIndex));
    setTotalPages(Math.ceil(trends?.length / ITEMS_PER_PAGE));
  }, [currentPage, trends]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // -------

  useEffect(() => {
    fetchTrends();
  }, []);

  useEffect(() => {
    if (result_list?.length > 0 || result_list_visual?.length > 0) {
      setShowContent(true);
    }
  }, [result_list, result_list_visual]);

  function fixLinkProtocol(url) {
    if (url.startsWith("http://")) {
      return url.replace("http://", "https://");
    }
    return url;
  }

  const fetchTrends = async () => {
    try {
      const response = await axios.get(
        "https://api.contentor.ca/api/v1/content/visual-content/",
        { headers: { Authorization: accessToken } }
      );

      set_resultList_visual(response.data.results);
      setNextVisualContent(response.data.next);
      setIsPrevVisualContent(false);
      if (response.data.next === null) {
        setIsNextVisualContent(false);
      }
    } catch (error) {
      const status = error.response?.status;

      if (status === 401) {
        localStorage.removeItem("accessToken");
        window.location.href = "/";
      } else {
      }
    }
  };

  const visualAccontPaginationHandler = (value) => {
    const mainValue = value === "next" ? nextVisualContent : prevVisualContent;

    const apiUrl = fixLinkProtocol(mainValue);

    axios
      .get(apiUrl, {
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        const { results, next, previous } = res.data;

        set_resultList_visual(results);
        setNextVisualContent(next);
        setPrevVisualContent(previous);
        setIsNextVisualContent(!!next);
        setIsPrevVisualContent(!!previous);
      })
      .catch((error) => {});
  };

  const resultPaginationHandler = (value) => {
    const mainValue = value === "next" ? nextResult : prevResult;

    const apiUrl = fixLinkProtocol(mainValue);

    axios
      .get(apiUrl, {
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        const { results, next, previous } = res.data;

        set_resultList(results);
        setNextResult(next);
        setPrevResult(previous);
        setIsNextResult(!!next);
        setIsPrevResult(!!previous);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setLoadingTrends(true);
    axios.get(`https://api.contentor.ca/api/v1/content/trend/`).then((res) => {
      setTrends(res.data);
      setLoadingTrends(false);
    });
  }, []);

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    //get propife  ---->
    axios
      .get("https://api.contentor.ca/api/v1/account/profile/", {
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        setuserName(res.data.results[0].user);
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.contentor.ca/api/v1/content/text-content/",
          { headers: { Authorization: accessToken } }
        );

        set_resultList(response.data.results);
        setNextResult(response.data.next);
        setIsPrevResult(false);
        if (response.data.next === null) {
          setIsNextResult(false);
        }
      } catch (err) {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
        setLoading(false);
      }
    };

    fetchData();

    //get propife visual  ---->

    axios
      .get("https://api.contentor.ca/api/v1/subscription/payment/", {
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        res.data.results.forEach((item) => {
          if (item.is_active) {
            set_visualAccont(true);
          }
        });
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });

    if (visualAccont) {
      axios
        .get("https://api.contentor.ca/api/v1/content/visual-content/", {
          headers: { Authorization: accessToken },
        })
        .then((res) => {})
        .catch((err) => {
          if (err.status === 401) {
            localStorage.removeItem("accessToken");
            window.location.href = "/";
          }
        });
    }
  }, []);

  const send_content = (e) => {
    e.preventDefault();
    setLoading(true);
    let accessToken = localStorage.getItem("accessToken");

    if (visualAccont) {
      axios
        .post(
          "https://api.contentor.ca/api/v1/content/visual-content/",
          {
            social_media: social_media,
            description: idea,
            language: languageId,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          navigate("/dashboard/visual-result", { state: res.data });
        })
        .catch((err) => {
          if (err.status === 400) {
            toast.error(
              "Request failed. Please try again later. Error: Invalid response from text content generation task."
            );
          }
          if (err.status)
            if (err.status === 401) {
              localStorage.removeItem("accessToken");
              window.location.href = "/";
            }
          setLoading(false);
        });
    } else {
      axios
        .post(
          "https://api.contentor.ca/api/v1/content/text-content/",
          {
            social_media: social_media,
            description: idea,
            language: languageId,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          // window.location.reload()
          navigate("/dashboard/result", { state: res.data });

          // toggle_create_content_component()
        })
        .catch((err) => {
          if (err.status === 401) {
            localStorage.removeItem("accessToken");
            window.location.href = "/";
          }
          if (err.status == 429) {
            setError("The number of requests exceeds the allowed limit");
          } else {
            setError(err.message);
          }

          setLoading(false);
        });
    }
  };

  const handleDownload = async (value) => {
    try {
      const response = await fetch(value);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "generated-image.jpg";
      link.click();
      toast.success("Image downloaded! 📥");
    } catch (error) {
      toast.error("Failed to download the image.");
    }
  };

  const handleCopy = (text, tags) => {
    const textToCopy = `${text}\n\n${tags.join(" ")}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => toast.success("Copied! 📋"))
      .catch(() => toast.error("Failed to copy!"));
  };

  //Free Creates---------------------->>

  let searched_list = result_list?.filter((item) => {
    if (item.generate_text_content !== null) {
      let discription_hastag = item.generate_text_content.toLowerCase().trim();
      return discription_hastag.includes(searchValue.toLowerCase().trim());
    }
  });
  let searched_list_visual = result_list_visual?.filter((item) => {
    if (item.generate_text_content !== null) {
      let discription_hastag = item.generate_text_content.toLowerCase().trim();
      return discription_hastag.includes(searchValue.toLowerCase().trim());
    }
  });

  const text = taxtType;

  const delay = 120;
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      setTimeout(() => {
        setCurrentText("");
        setCurrentIndex(0);
      }, 1000);
    }
  }, [currentIndex, delay, text]);

  const handleChange = (selectedOption) => {
    setsSocial_media(selectedOption.value);
  };

  const handleFocus = () => {
    setPlaceholder("Search...");
  };

  const handleBlur = () => {
    if (!selectedOption) {
      setPlaceholder("Languages");
    }
  };

  useEffect(() => {
    const options = [
      { value: "instagram", label: "Instagram" },
      { value: "telegram", label: "Telegram" },
      { value: "snapchat", label: "SnapChat" },
      { value: "youtube", label: "Youtube" },
      { value: "whatsapp", label: "Whatsapp" },
      { value: "tiktok", label: "TikTok" },
      { value: "twitter", label: "Twitter" },
    ];
    setOpt(options);
  }, []);

  const onFocusText = () => {
    setTaxtType("");
  };

  const onMouseLeaveText = () => {
    setTaxtType(
      "Write your idea here for generating content, or use the trends below."
    );
  };

  return (
    <>
      <header className=" mb-6 p-4 flex sm:flex-row flex-col gap-3 justify-between">
        <div>
          <p className=" font-bold text-3xl text text-gray900">
            Welcome back, {userName}
          </p>
          <small className=" flex text-gray600 gap-1 mt-3">
            try <p className="text-brand600"> premium </p>for create endless
            posts
          </small>
        </div>
        <button
          onClick={toggle_create_content_component}
          className="h-min w-28 text-center justify-center items-center text-white bg-brand600 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px]"
        >
          + Create
        </button>
      </header>

      {/* results----------------------> */}
      {showContent ? (
        <section className={`w-full flex flex-col gap-6`}>
          <div className="w-full flex md:justify-start justify-center md:p-0 p-4">
            <div className="md:w-[30%] w-full p-2 rounded-lg flex gap-3 border-[2px] border-gray300">
              <img src={search} />
              <input
                onChange={(e) => set_searchValue(e.target.value)}
                className="w-[90%] outline-none"
                placeholder="Search"
              ></input>
            </div>
          </div>

          {/* row */}
          <section>
            <div className="flex gap-3 my-5">
              <img src={permium} className="w-6" />
              <p className="text-gray700 font-bold">Premium Creates</p>
            </div>
            <div className="border-[2px] border-gray200 rounded-xl">
              <div className="w-full max-h-[600px] overflow-y-scroll scrollbar-hidden">
                <div className="w-full flex md:px-6 px-2 py-4 gap-3">
                  <p className="text-gray600 md:w-[10%] ">Thumbnail</p>
                  <p className="text-gray600 md:w-[62%] md:m-0 mr-8">Content</p>
                  <p className="text-gray600">Tags</p>
                </div>
                {searched_list_visual?.map((item, index) => {
                  let string_of_tags = item.tag;
                  const formattedTags = JSON.parse(
                    string_of_tags.replace(/'/g, '"')
                  );

                  const allTags = formattedTags.map(
                    (tag) => `#${tag.slice(1)}`
                  );

                  return (
                    <>
                      <div
                        onClick={() =>
                          navigate("/dashboard/visual-result", { state: item })
                        }
                        className="cursor-pointer w-full md:px-6 py-4 ml-4  md:m-0 flex items-center justify-between md:gap-6 gap-1 border-b-[2px] border-gray200"
                      >
                        <div className="md:w-[10%] flex items-center md:mr-0 mr-8 md:gap-3 gap-1">
                          <img className="w-6 hidden md:inline" src={link} />
                          <img
                            className="md:w-10 w-20  rounded-md"
                            src={item.generate_image_content}
                          />
                        </div>

                        <div className="w-[70%]">
                          <p className=" text-sm text-gray600 max-h-[4em] overflow-hidden ">
                            {item.generate_text_content}
                          </p>
                        </div>

                        <div>
                          <p className="bg-blue-light-50 text-blue-light-700 text-center flex flex-wrap items-center justify-center py-1 px-4 rounded-2xl gap-2 text-sm">
                            {allTags[0]}
                          </p>
                        </div>

                        <div className="flex gap-3 items-center w-32 max-[768px]:flex-col">
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(item.generate_text_content, allTags);
                            }}
                            className=" cursor-pointer"
                            src={copy}
                          />
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(item.generate_image_content);
                            }}
                            className=" cursor-pointer"
                            src={download}
                          />
                          <div>
                            {loading_remove_visual === item.id ? (
                              <div className="loader_remove"></div>
                            ) : (
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  remove_item_handeler_visual(item.id);
                                }}
                                className=" cursor-pointer"
                                src={remove}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="p-2 space-x-3">
                {isPrevVisualContent && (
                  <button
                    onClick={() => visualAccontPaginationHandler("prev")}
                    className="border-[2px] border-gray-200 p-2 rounded-md"
                  >
                    Previous
                  </button>
                )}
                {isNextVisualContent && (
                  <button
                    onClick={() => visualAccontPaginationHandler("next")}
                    className="border-[2px] border-gray-200 p-2 rounded-md"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className="flex gap-3 my-5">
              <img className="w-6" src={free} />
              <p className="text-gray700 font-bold">Free Creates</p>
            </div>
            <div className="border-[2px]  border-gray200 rounded-xl">
              <div className="w-full max-h-[600px] overflow-y-scroll scrollbar-hidden">
                <div className="w-full flex md:px-6 px-2 py-4 gap-3 border-b-[2px] border-gray200">
                  <p className="text-gray600 md:w-[10%] ">Thumbnail</p>
                  <p className="text-gray600 md:w-[62%] md:m-0 mr-8">Content</p>
                  <p className="text-gray600">Tags</p>
                </div>
                {searched_list?.map((item) => {
                  let string_of_tags = item.tag;
                  const formattedTags = JSON.parse(
                    string_of_tags.replace(/'/g, '"')
                  );

                  const allTags = formattedTags.map(
                    (tag) => `#${tag.slice(1)}`
                  );

                  return (
                    <>
                      <div
                        onClick={() =>
                          navigate("/dashboard/result", { state: item })
                        }
                        className="cursor-pointer w-full md:px-6 py-4 ml-4  md:m-0 flex items-center justify-between md:gap-6 gap-1 border-b-[2px] border-gray200  "
                      >
                        <div className="md:w-[10%] flex items-center md:mr-0 mr-8 md:gap-3 gap-1">
                          <img className="w-6 hidden md:inline" src={link} />
                          <img
                            className="md:w-10 w-20  rounded-md"
                            src={image_result}
                          />
                        </div>

                        <div className="w-[70%]">
                          <p className=" text-sm text-gray600 max-h-[4em] overflow-hidden ">
                            {item.generate_text_content}
                          </p>
                        </div>

                        <div>
                          <p className="bg-blue-light-50 text-blue-light-700 text-center flex flex-wrap items-center justify-center py-1 px-4 rounded-2xl gap-2 text-sm">
                            {allTags[0]}
                          </p>
                        </div>

                        <div className="flex gap-3 items-center w-32 max-[768px]:flex-col">
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(item.generate_text_content, allTags);
                            }}
                            className=" cursor-pointer"
                            src={copy}
                          />
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(image_result);
                            }}
                            className="cursor-pointer"
                            src={download}
                          />
                          <div>
                            {loading_remove === item.id ? (
                              <div className="loader_remove"></div>
                            ) : (
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  remove_item_handeler(item.id);
                                }}
                                className=" cursor-pointer"
                                src={remove}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="p-2 space-x-3">
                {isPrevResult && (
                  <button
                    onClick={() => resultPaginationHandler("prev")}
                    className="border-[2px] border-gray-200 p-2 rounded-md"
                  >
                    Previous
                  </button>
                )}

                {isNextResult && (
                  <button
                    onClick={() => resultPaginationHandler("next")}
                    className="border-[2px] border-gray-200 p-2 rounded-md"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </section>
        </section>
      ) : (
        <section className={`w-full flex h-[70vh] justify-center items-center`}>
          <div className="flex absolute flex-col gap-4 p-3 border-[2px] border-gray-200 rounded-xl shadow sm:w-[40%] max-w-[600px]">
            <div className="flex justify-between p-2">
              <p className="font-bold text-lg text-gray-800">Create new post</p>
              <img
                onClick={toggle_create_content_component}
                className="cursor-pointer h-[10px]"
                src={closeIcon}
              />
            </div>
            <form
              onSubmit={send_content}
              className="flex flex-col gap-3 text-[#717680]"
            >
              <Select
                defaultOptions
                onChange={handleChange}
                options={opt}
                placeholder="Select a social media"
                className={`border-2 border-gray300 outline-none p-3 w-full rounded-xl bg-white`}
                isClearable // Allow the user to clear the selection
              />

              <AsyncSelect
                placeholder={placeholder}
                className={`border-2 border-gray300 outline-none p-3 w-full rounded-xl bg-white`}
                cacheOptions
                defaultOptions
                onChange={(e) => {
                  setLanguageId(e.value);
                  setSelectedOption(e);
                }}
                loadOptions={fetchOptions}
                onFocus={handleFocus} // Change placeholder text on focus
                onBlur={handleBlur} // R
              />

              <textarea
                onChange={(e) => {
                  {
                    const currentValue = e.target.value;
                    setIdea(currentValue);
                  }
                }}
                placeholder={currentText}
                onFocus={onFocusText}
                onBlur={onMouseLeaveText}
                value={idea}
                className="h-[120px] p-3 border-2 border-gray-200 rounded-lg outline-none w-full resize-none placeholder:text-gray-500 placeholder:font-bold"
              ></textarea>
              {loadingTrends ? (
                <p>loading</p>
              ) : (
                trends?.length > 0 && (
                  <div className="flex items-center flex-wrap gap-4 text-gray600">
                    <p>Trends: </p>
                    {currentPage !== 1 && (
                      <div className="cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                        <button
                          className="text-brand700"
                          onClick={handlePrevPage}
                        >
                          Previous
                        </button>
                      </div>
                    )}

                    {currentItems?.map((item) => {
                      return (
                        <div
                          onClick={() => setIdea(item.title)}
                          className="transition-all duration-300 cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1"
                        >
                          <div className=" w-2 h-2 bg-brand500 rounded-full"></div>
                          <p className="text-brand700">{item.title}</p>
                        </div>
                      );
                    })}
                    {currentPage !== totalPages && (
                      <div className="cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                        <button
                          className="text-brand700"
                          onClick={handleNextPage}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                )
              )}

              <button
                disabled={loading}
                type="submit"
                className=" w-full bg-brand600 flex justify-center items-center py-3 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg font-bold "
              >
                {loading ? <div className="loader"></div> : "Run"}
              </button>
            </form>
          </div>
        </section>
      )}

      {error && (
        <ErrorComponent message={error} onClose={() => setError(null)} />
      )}
    </>
  );

  function remove_item_handeler(e) {
    setLoading_remove(e);
    const url = `https://api.contentor.ca/api/v1/content/text-content/${e}/`;

    axios
      .put(
        url,
        { is_deleted: true },
        { headers: { Authorization: accessToken } }
      )
      .then((res) => {
        setLoading(false);

        set_resultList(
          result_list?.filter((item) => {
            return item.id !== e;
          })
        );
        setLoading_remove(null);
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }

        setLoading(false);
        setError(err.message);
        setLoading_remove(null);
      });
  }

  function remove_item_handeler_visual(e) {
    setLoading_remove_visual(e);
    const url = `https://api.contentor.ca/api/v1/content/visual-content/${e}/`;

    axios
      .put(
        url,
        { is_deleted: true },
        { headers: { Authorization: accessToken } }
      )
      .then((res) => {
        set_resultList_visual(
          result_list_visual?.filter((item) => {
            return item.id !== e;
          })
        );
        setLoading_remove_visual(null);
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });
  }

  function toggle_create_content_component() {
    setShowContent(false);
  }
}

function Main() {
  return (
    <>
      <section className="md:p-10 p-5">
        <Results />
      </section>
    </>
  );
}
export default Main;

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import email_icon from "./../assets/icons/email.svg";
import logo from "./../assets/icons/logo.svg";
import axios from "axios";
import toast from "react-hot-toast";

function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [loading, setloading] = useState(false);
  const [errorforName, seterrorName] = useState("border-2 border-gray300");
  const [errorforPass, seterrorPass] = useState("border-2 border-gray300");
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(true);
  const token = localStorage.getItem("accessToken");

  const togglePasswordVisibility = () => {
    setShowPass((prev) => !prev);
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  function submitHandler(e) {
    e.preventDefault();
    if (email === "" || name === "" || pass1 === "" || pass2 === "") {
      name === ""
        ? seterrorName("border-2 border-brand600")
        : seterrorName("border-2 border-gray300");
      pass1 === ""
        ? seterrorPass("border-2 border-brand600")
        : seterrorPass("border-2 border-gray300");
    } else {
      seterrorName("border-2 border-gray300");
      seterrorPass("border-2 border-gray300");
      setloading(true);
      axios
        .post("https://api.contentor.ca/api/v1/account/auth/token/register/", {
          username: name.toLowerCase(),
          email: email.toLowerCase(),
          password: pass1,
          password2: pass2,
        })
        .then(function (response) {
          localStorage.setItem("accessToken", `Bearer ${response.data.access}`);
          if (response.status === 201 && response.data.error === false) {
            navigate("/dashboard");
          }
        })
        .catch(function (error) {
          toast.error(error.response.data.message);
        })
        .finally(() => setloading(false));
    }
  }

  return (
    <>
      <header
        className={` sm:px-10 px-4 pt-4 pb-2 flex justify-between items-center sticky top-0 w-full bg-white z-10`}
      >
        <Link to="/" className="flex justify-center items-center gap-1vw">
          <img src={logo} alt="i" />
          <p>CONTENTOR</p>
        </Link>
      </header>
      <div className="w-full flex justify-center py-4 sm:h-auto h-[85vh] items-center mb-12">
        <div className="flex flex-col gap-4 sm:w-[80%] max-w-[400px] w-[90%]">
          <div className="flex gap-1 flex-col">
            <p className="text-gray900 font-bold text-2xl">Sign up</p>
            <p className=" text-l text-gray-500">
              Start using your powerfull content gen.
            </p>
          </div>

          <form onSubmit={submitHandler} className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <label className="text-gray700 font-bold" htmlFor="name">
                Username*
              </label>
              <input
                className={` ${errorforName} outline-none p-3 w-full rounded-xl`}
                id="name"
                placeholder="Enter your username"
                type="text"
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-gray700 font-bold" htmlFor="email">
                Email*
              </label>
              <div
                className={`${errorforPass} flex justify-between rounded-xl p-3 w-full`}
              >
                <input
                  className="outline-none"
                  onChange={(e) => setEmail(e.target.value)}
                  id="pass"
                  type="text"
                  placeholder="Enter your email or username"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-gray700 font-bold" htmlFor="pass">
                Password*
              </label>
              <div
                className={`${errorforPass} flex justify-between rounded-xl p-3 w-full`}
              >
                <input
                  className="outline-none"
                  onChange={(e) => setPass1(e.target.value)}
                  id="pass"
                  type={showPass ? "password" : "text"}
                  placeholder="Enter your password"
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer"
                >
                  {showPass ? (
                    <img src="/eye-off.svg" width={20} alt="password" />
                  ) : (
                    <img src="/eye-show.svg" width={20} alt="password" />
                  )}
                </div>
              </div>

              <div
                className={`${errorforPass} flex justify-between rounded-xl p-3 w-full`}
              >
                <input
                  className="outline-none"
                  onChange={(e) => setPass2(e.target.value)}
                  id="pass"
                  type={showPass ? "password" : "text"}
                  placeholder="Enter your password"
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer"
                >
                  {showPass ? (
                    <img src="/eye-off.svg" width={20} alt="password" />
                  ) : (
                    <img src="/eye-show.svg" width={20} alt="password" />
                  )}
                </div>
              </div>
            </div>
            <small className="text-gray-500">
              Must be at least 8 characters.
            </small>
            <button
              disabled={loading}
              className="w-full flex justify-center items-center bg-brand600 py-3 mt-3 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg font-bold "
              type="submit"
            >
              {loading ? <div className="loader"></div> : "Create account"}
            </button>
            <span class="line">
              <h2>
                <span>OR</span>
              </h2>
            </span>
            <Link
              rel="noopener noreferrer"
              to="https://accounts.google.com/o/oauth2/v2/auth?client_id=771544788877-c9mgdknmv4331o69ni4bsiif2i60td88.apps.googleusercontent.com&redirect_uri=https://contentor.ca/signin/google/&response_type=code&scope=email%20profile&access_type=offline"
              className="border border-[#D5D7DA] flex justify-center space-x-2 rounded-lg py-3 gap-x-1.5 cursor-pointer my-5"
            >
              <p>Sign up with Google</p>
              <img src="/google-icon.svg" alt="icon" />
            </Link>
            <p className="text-center text-gray600">
              Already have an account?
              <Link className=" cursor-pointer" to="/signin">
                <b className="text-brand600">Sign in</b>
              </Link>
            </p>
          </form>
        </div>
      </div>
      <footer className="flex w-full justify-between sm:px-8 sm:py-4 p-4 text-gray600 fixed bg-white  bottom-0 ">
        <p>© Contentor.ca</p>
        <div className="flex gap-2">
          <img className="w-5" src={email_icon} />
          <p>help@example.com</p>
        </div>
      </footer>
    </>
  );
}

export default Signup;

import { useEffect, useState } from "react";
import axios from "axios";
import profile from "./../assets/images/profile-icon-design-free-vector.jpg";

function isTokenValid(token) {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = payload.exp * 1000; 

    return Date.now() < expirationTime;
  } catch (error) {

    return false;
  }
}
function Main(props) {
  const [avatar, setAvatar] = useState("");
  useEffect(() => {
    async function getAvatar(params) {
      let accessToken = localStorage.getItem("accessToken");
      const response = await axios
        .get("https://api.contentor.ca/api/v1/account/profile/", {
          headers: {
            Authorization: accessToken,
          },
        })
        .catch((err) => {
          if (err.status === 401) {
            localStorage.removeItem("accessToken");
            window.location.href = "/";
          }
        });
      if (response.data.results[0].avatar.startsWith("http://")) {
        response.data.results[0].avatar =
          response.data.results[0].avatar.replace("http://", "https://");
      }
      response.data.results[0].avatar === null
        ? setAvatar(profile)
        : setAvatar(response.data.results[0].avatar);
    }

    getAvatar();
  });
  return (
    <img
      className={`w-10 h-10 rounded-[50%] object-cover`}
      src={avatar}
    />
  );
}
export default Main;

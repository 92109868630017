import { useState, useEffect } from "react";
import plus from "./../assets/icons/plus.svg";
import mines from "./../assets/icons/mines.svg";
import Avatar from "./../assets/images/Avatar.png";
import team9 from "./../assets/images/team9.png";
import team10 from "./../assets/images/team10.png";
import axios from "axios";

import { Link } from "react-router-dom";

var questions_list = [
  {
    title: "Is there a free trial available?",
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    title: "Can I change my plan later?",
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    title: "What is your cancellation policy?",
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    title: "Can other info be added to an invoice?",
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    title: "How does billing work?",
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    title: "How do I change my account email?",
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
];
function Question_box_creataor(props) {
  const [button, setButton] = useState(plus);
  const [isOpen, setIsOpne] = useState(false);

  return (
    <div
      onClick={() => {
        onclick();
      }}
      className=" cursor-pointer flex items-center flex-col w-full px-4"
    >
      <div className="w-full sm:w-[80%] flex items-start justify-between mt-8">
        <p className="font-bold text-gray-900">{props.question}</p>
        <button>
          <img src={button} />
        </button>
      </div>
      <p
        className={`text-gray-600 mt-6 w-full sm:w-[80%]  transition-all duration-300 ${
          isOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
        } overflow-hidden`}
      >
        {props.answer}
      </p>

      <div
        className={`h-[1px] w-full sm:w-[80%] bg-gray-300 mt-4 ${
          props.length - 1 === props.index ? "hidden" : "inline-block"
        }`}
      ></div>
    </div>
  );

  function onclick() {
    if (button === plus) {
      setButton(mines);
      setIsOpne(true);
    } else {
      setButton(plus);
      setIsOpne(false);
    }
  }
}

function Still_hvae_question(params) {
  return (
    <div className="w-full flex flex-col items-center gap-6 mt-9 px-2 py-10 my-24 bg-gray50">
      <div className="flex w-full justify-center">
        <img
          src={team9}
          className=" transform translate-x-5  translate-y-3 h-16 w-16 rounded-[50%] object-cover"
        />
        <img
          src={Avatar}
          className="z-[5] border border border-[3px] border-white rounded-full"
        />
        <img
          src={team10}
          className="transform translate-x-[-1.5rem] translate-y-3 h-16 w-16 rounded-[50%] object-cover"
        />
      </div>
      <div className="text-center flex flex-col gap-4">
        <p className="font-bold">Still have questions?</p>
        <p className="text-gray-500">
          Can’t find the answer you’re looking for? Please chat to our friendly
          team.
        </p>
      </div>
      <Link to={"contact-us"}>
        <button className=" bg-brand600 px-4 py-2 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg ">
          Get in touch
        </button>
      </Link>
    </div>
  );
}

function Question(params) {
  const [questions, set_questions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    //get API ---->
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.contentor.ca/api/v1/support/faq/"
        );
        set_questions(response.data);

        //scrolling --->
        setTimeout(() => {
          if (params.scroll === true) {
            const section = document.getElementById("faq");
            section.scrollIntoView({ behavior: "smooth" });
          }
        }, 500);
        //-------------------------
      } catch (err) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params.scroll]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  else {
    return (
      <div className="flex mt-28 flex-col relative">
        <div id="faq" className=" absolute top-[-80px]"></div>

        <div className="text-center w-full flex flex-col gap-6 px-[5px]">
          <p className="font-bold text-3xl text-gray900">
            Frequently asked questions
          </p>
          <p className="text-gray600 mb-10">
            Everything you need to know about the product and billing.
          </p>
        </div>

        {/* Boxs */}
        {questions.map((item, index) => {
          return (
            <Question_box_creataor
              question={item.question}
              answer={item.answer}
              index={index}
              length={questions.length}
            />
          );
        })}

        <Still_hvae_question />
      </div>
    );
  }
}

export default Question;

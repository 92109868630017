import axios from "axios";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const GoogleAuth = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post("https://api.contentor.ca/api/v1/account/auth/token/google/", {
        code,
      })
      .then((response) => {
        localStorage.setItem("accessToken", `Bearer ${response.data.access}`);
        if (response.status === 200) {
          navigate("/dashboard");
        }
      })
      .catch((error) => toast.error(error.response.data.message));
  }, []);

  return (
    <div className="flex justify-center items-center h-[45vh]">
      <div className="flex flex-col items-center gap-y-6">
        <p className="bg-[#e31b54] text-white p-2 rounded-lg font-medium">
          Wating to login...
        </p>
        <div className="loader-2"></div>
      </div>
    </div>
  );
};

export default GoogleAuth;

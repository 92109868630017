import { lazy, Suspense } from "react";
import email from "./../assets/icons/email.svg";
import logo from "./../assets/icons/logo.svg";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../components/socialMedia/layout";
import MobileLayout from "../components/socialMedia/mobileLayout";
// Lazy load social media components
const Instagram = lazy(() => import("../components/socialMedia/instagram"));
const Whatsapp = lazy(() => import("../components/socialMedia/whatsapp"));
const Telegram = lazy(() => import("../components/socialMedia/telegram"));
const Youtube = lazy(() => import("../components/socialMedia/youtube"));
const Twitter = lazy(() => import("../components/socialMedia/twitter"));
const Snapchat = lazy(() => import("../components/socialMedia/snapchat"));
const Tiktok = lazy(() => import("../components/socialMedia/tiktok"));

function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tags, setTags] = useState([]);
  const resultData = location.state;
  const token = localStorage.getItem("accessToken");
  
  useEffect(() => {
    if (resultData) {
      let string_of_tags = resultData.tag;
      try {
        const arr = JSON.parse(string_of_tags.replace(/'/g, '"'));
        setTags(arr.map((tag) => tag.slice(1)));
      } catch (error) {}
    } else {
      // navigate("/error");
    }
  }, [resultData, navigate]);

  return (
    <>
      {/* Header */}
      <header className="sm:px-10 px-4 pt-4 pb-2 flex justify-between items-center sticky top-0 w-full bg-white z-0 shadow-none md:shadow-md">
        <Link to="/" className="hidden md:flex items-center gap-3">
          <img src={logo} alt="Logo" className="w-8 h-8" />
          <p className="text-xl font-bold text-gray-800">CONTENTOR</p>
        </Link>
        <Link className="hidden md:flex" to={token ? "/dashboard" : "/signin"}>
          <button className="px-6 py-2 bg-brand600 text-white rounded-md text-sm hover:scale-105 transition">
            {token ? "Dashboard" : "Sign in"}
          </button>
        </Link>
      </header>
      <div className="flex min-h-screen pb-40 md:pb-24">
        <div className="hidden md:block w-1/4 p-8 space-y-3">
          <Layout tags={tags} text={resultData?.generate_text_content} />
        </div>
        <div className="md:hidden fixed bottom-0 w-full p-3 bg-white">
          <MobileLayout tags={tags} text={resultData?.generate_text_content} />
        </div>
        <div className="flex justify-center w-full md:w-3/4 pt-5 md:pt-0">
          <Suspense fallback="loading">
            {resultData?.social_media === "instagram" && (
              <Instagram
                tags={tags}
                text={resultData?.generate_text_content}
                description={resultData?.description}
              />
            )}
            {resultData?.social_media === "whatsapp" && (
              <Whatsapp
                tags={tags}
                text={resultData?.generate_text_content}
                name={resultData?.full_name}
                date={resultData?.created_at}
                description={resultData?.description}
              />
            )}
            {resultData?.social_media === "telegram" && (
              <Telegram
                tags={tags}
                text={resultData?.generate_text_content}
                name={resultData?.full_name}
                date={resultData?.created_at}
                description={resultData?.description}
              />
            )}
            {resultData?.social_media === "youtube" && (
              <Youtube
                tags={tags}
                text={resultData?.generate_text_content}
                date={resultData?.created_at}
                description={resultData?.description}
              />
            )}
            {resultData?.social_media === "twitter" && (
              <Twitter
                tags={tags}
                text={resultData?.generate_text_content}
                date={resultData?.created_at}
                description={resultData?.description}
              />
            )}
            {resultData?.social_media === "snapchat" && (
              <Snapchat
                tags={tags}
                text={resultData?.generate_text_content}
                description={resultData?.description}
              />
            )}
            {resultData?.social_media === "tiktok" && (
              <Tiktok
                tags={tags}
                text={resultData?.generate_text_content}
                description={resultData?.description}
              />
            )}
          </Suspense>
        </div>
      </div>

      {/* Footer */}
      <footer className="hidden md:flex w-full justify-between sm:px-8 sm:py-4 p-4 text-gray-600 fixed bg-white bottom-0 shadow-inner">
        <p>© Contentor.ca</p>
        <div className="flex gap-2 items-center">
          <img className="w-5" src={email} alt="Email" />
          <p>help@example.com</p>
        </div>
      </footer>
    </>
  );
}

export default Main;

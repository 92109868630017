import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import Cards from "./cards";
import card1 from "./../assets/images/card1.png";
import card2 from "./../assets/images/card2.png";
import card3 from "./../assets/images/card3.png";
import card4 from "./../assets/images/card4.png";
import card5 from "./../assets/images/card5.png";
import card6 from "./../assets/images/card6.png";
import card7 from "./../assets/images/card7.png";
import card8 from "./../assets/images/card8.png";
import card9 from "./../assets/images/card9.png";
import x from "./../assets/images/x.svg";
import instagram from "./../assets/icons/instagram.svg";
import Telegram from "./../assets/icons/telegram.svg";
import TikTok from "./../assets/icons/Tik Tok.svg";
import Whatsapp from "./../assets/icons/whatsapp.svg";
import Youtube from "./../assets/icons/youtube.svg";
import axios from "axios";
import toast from "react-hot-toast";

var cards_list = [
  { border: "border-[#00D95F]", image: card1, icon: Whatsapp },
  { border: "border-[#FF0000]", image: card2, icon: x },
  { border: "border-black", image: card3, icon: x },
  { border: "border-[#FF0000]", image: card4, icon: Youtube },
  { border: "border-black", image: card5, icon: TikTok },
  { border: "border-[#FF7900]", image: card6, icon: x },
  { border: "border-[#FB00FF]", image: card7, icon: instagram },
  { border: "border-[#FF0000]", image: card8, icon: Telegram },
];

function Creator(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [social_media, setsSocial_media] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingTrends, setLoadingTrends] = useState(false);
  const [errorforName, seterrorName] = useState("border-2 border-gray300");
  const [errorforEmail, seterrorEmail] = useState("border-2 border-gray300");
  const [errorforMedia, seterrorMedia] = useState("border-2 border-gray300");
  const [errorforIdea, seterrorIdea] = useState("border-2 border-gray300");
  const [trends, setTrends] = useState([]);
  const [languageId, setLanguageId] = useState();
  const [description, setDescription] = useState("");
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [placeholder, setPlaceholder] = useState("Languages");
  const [selectedOption, setSelectedOption] = useState(null);
  const [taxtType, setTaxtType] = useState(
    "Write your idea here for generating content, or use the trends below."
  );
  const [opt, setOpt] = useState([]);

  const ITEMS_PER_PAGE = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setCurrentItems(trends.slice(startIndex, endIndex));
    setTotalPages(Math.ceil(trends.length / ITEMS_PER_PAGE));
  }, [currentPage, trends]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setDescription(props.tag);
  }, [props.tag]);

  useEffect(() => {
    setLoadingTrends(true);
    axios.get(`https://api.contentor.ca/api/v1/content/trend/`).then((res) => {
      setTrends(res.data);
      setLoadingTrends(false);
    });
  }, []);

  const fetchOptions = async (inputValue) => {
    try {
      const response = await axios.get(
        "https://api.contentor.ca/api/v1/content/language/"
      );

      const filteredOptions = response.data
        .filter((item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => ({
          label: item.name,
          value: item.id,
        }));

      return filteredOptions;
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  const send_content = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (name !== "" && email !== "" && social_media !== "") {
      if (!emailRegex.test(email)) {
        toast.error(
          "Invalid email format. Please enter a valid email (e.g., user@example.com)."
        );
        return;
      }

      setloading(true);
      axios
        .post("https://api.contentor.ca/api/v1/content/guest-content/", {
          full_name: name,
          email,
          social_media,
          description,
          language: languageId,
        })
        .then((res) => {
          toast.success(res.data.message);
          setloading(false);
          navigate("/guest-result", { state: res.data.data });
        })
        .catch((err) => {
          if (err.status === 400) {
            toast.error(
              "There was a problem with your request. Please try again later."
            );
          }
          if (err.status === 429) {
            toast.error("Too many request, please try again later");
          }
          setloading(false);
        });
    } else {
      name === ""
        ? seterrorName("border-2 border-brand600")
        : seterrorName("border-2 border-gray300");
      email === ""
        ? seterrorEmail("border-2 border-brand600")
        : seterrorEmail("border-2 border-gray300");
      social_media === ""
        ? seterrorMedia("border-2 border-brand600")
        : seterrorMedia("border-2 border-gray300");
      description === ""
        ? seterrorIdea("border-2 border-brand600")
        : seterrorIdea("border-2 border-gray300");
    }
  };
  const text = taxtType;
  const delay = 120;
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      setTimeout(() => {
        setCurrentText("");
        setCurrentIndex(0);
      }, 1000);
    }
  }, [currentIndex, delay, text]);

  const handleFocus = () => {
    setPlaceholder("Search...");
  };

  const handleBlur = () => {
    if (!selectedOption) {
      setPlaceholder("Languages");
    }
  };

  const handleChange = (selectedOption) => {
    setsSocial_media(selectedOption.value);
  };

  useEffect(() => {
    const options = [
      { value: "instagram", label: "Instagram" },
      { value: "telegram", label: "Telegram" },
      { value: "snapchat", label: "SnapChat" },
      { value: "youtube", label: "Youtube" },
      { value: "whatsapp", label: "Whatsapp" },
      { value: "tiktok", label: "TikTok" },
      { value: "twitter", label: "Twitter" },
    ];
    setOpt(options);
  }, []);

  const onFocusText = () => {
    setTaxtType("");
  };

  const onMouseLeaveText = () => {
    setTaxtType(
      "Write your idea here for generating content, or use the trends below."
    );
  };

  return (
    <>
      <div className="flex relative md:flex-row flex-col-reverse gap-10 md:gap-0 px-5 md:pl-20 justify-between">
        <div id="trend" className=" absolute top-[-100px]"></div>

        <div className="flex flex-col gap-4 md:w-[40%]">
          <b className="text-4xl text-brand600">CONTENTOR</b>
          <b className="text-4xl text-gray900">
            the easiest way to create social media content
          </b>
          <div>
            <form
              onSubmit={send_content}
              className="flex flex-col gap-3 text-[#717680]"
            >
              <div className="flex gap-4">
                <input
                  className={`${errorforName}  outline-none p-3 w-full border-2 rounded-xl placeholder:text-[#717680]`}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                ></input>
                <input
                  className={`${errorforEmail} outline-none p-3 w-full border-2 rounded-xl placeholder:text-[#717680]`}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Your email"
                ></input>
              </div>
              <div className="flex max-[768px]:flex-col gap-4 text-[#717680]">
                <Select
                  onChange={handleChange}
                  options={opt}
                  placeholder="Select a social media"
                  className={`${errorforMedia} outline-none p-3 w-full rounded-xl bg-white`}
                  name="social media"
                />
                <AsyncSelect
                  placeholder={placeholder}
                  className={`${errorforMedia} outline-none p-3 w-full rounded-xl bg-white`}
                  cacheOptions
                  defaultOptions
                  onChange={(e) => {
                    setLanguageId(e.value);
                    setSelectedOption(e);
                  }}
                  loadOptions={fetchOptions}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>

              <textarea
                onChange={(e) => {
                  {
                    const currentValue = e.target.value;
                    setDescription(currentValue);
                  }
                }}
                onFocus={onFocusText}
                onBlur={onMouseLeaveText}
                value={description}
                placeholder={currentText}
                className={`${errorforIdea} h-[120px] p-3 rounded-lg outline-none w-full resize-none placeholder:text-gray-500 placeholder:font-bold`}
              ></textarea>
              {loadingTrends ? (
                <p>loading</p>
              ) : (
                trends.length > 0 && (
                  <div className="flex items-center flex-wrap gap-4 text-gray600">
                    <p>Trends: </p>
                    {currentPage !== 1 && (
                      <div className="cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                        <button
                          className="text-brand700"
                          onClick={handlePrevPage}
                        >
                          Previous
                        </button>
                      </div>
                    )}

                    {currentItems.map((item) => {
                      return (
                        <div
                          onClick={() => props.setTag(item.title)}
                          className="transition-all duration-300 cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1"
                        >
                          <div className=" w-2 h-2 bg-brand500 rounded-full"></div>
                          <p className="text-brand700">{item.title}</p>
                        </div>
                      );
                    })}
                    {currentPage !== totalPages && (
                      <div className="cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                        <button
                          className="text-brand700"
                          onClick={handleNextPage}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                )
              )}

              <button
                disabled={loading}
                type="submit"
                className="w-full flex justify-center items-center bg-brand600 py-3 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg font-bold "
              >
                {loading ? <div className="loader"></div> : "Run"}
              </button>
            </form>
          </div>
        </div>
        <div className="w-full md:hidden flex">
          <Swiper
            modules={[Pagination, Autoplay, Scrollbar, A11y]}
            loop={true}
            slidesPerView={2}
            observer={true}
            observeParents={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={3000}
            className=" hidden w-full  justify-center gap-10"
          >
            {cards_list?.map((item) => {
              return (
                <SwiperSlide className="flex justify-center text-center ml-3">
                  <Cards
                    border={item.border}
                    image={item.image}
                    icon={item.icon}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="md:flex hidden relative">
          <div className=" flex items-end gap-4 sticky top-[-100px] right-0 transform rotate-[-25deg]">
            <div className="md:flex-col flex-row flex gap-6">
              <Cards
                id="animation1"
                border="border-[#00D95F]"
                image={card1}
                icon={Whatsapp}
              />
              <Cards
                id="animation2"
                border="border-[#FF0000]"
                image={card2}
                icon={x}
              />
            </div>
            <div className="md:flex-col flex-row flex gap-6">
              <Cards
                id="animation3"
                border="border-black"
                image={card3}
                icon={x}
              />
              <Cards
                id="animation4"
                border="border-[#FF0000]"
                image={card4}
                icon={Youtube}
              />
              <Cards
                id="animation5"
                border="border-black"
                image={card5}
                icon={TikTok}
              />
              <Cards
                id="animation6"
                border="border-[#FF7900]"
                image={card6}
                icon={x}
              />
            </div>
            <div className="md:flex-col flex-row flex gap-6">
              <Cards
                id="animation5"
                border="border-[#FB00FF]"
                image={card7}
                icon={instagram}
              />
              <Cards
                id="animation5"
                border="border-[#34AADF]"
                image={card8}
                icon={Telegram}
              />
              <Cards
                id="animation5"
                border="border-[#FF0000]"
                image={card9}
                icon={x}
              />
            </div>
          </div>
        </div>
        <div className="w-full h-[200px] md:inline-block hidden bg-white absolute left-0 bottom-[-100px]">
          <div className="w-full flex justify-center">
            <div className="sm:w-[90%] z-[5] w-[90%] h-[1.5px] bg-gray200  content-center"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Creator;

import plusIcon from "./../assets/icons/plus_white.svg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SuccesComponent from "./sucssec";

const Status_component = (props) => {
  if (props.state === "Active") {
    return (
      <>
        <div
          className={`py-1 px-3 w-min  bg-succes50 flex items-center gap-2 rounded-2xl`}
        >
          <div className={` bg-succes500 w-2 h-2 rounded-full`}></div>
          <p className="text-succes700">Active</p>
        </div>
      </>
    );
  } else if (props.state === "Inactive") {
    return (
      <div
        className={`py-1 px-3 w-min  bg-error50 flex items-center gap-2 rounded-2xl`}
      >
        <div className={` bg-error500 w-2 h-2 rounded-full`}></div>
        <p className="text-[#B42318]">Inactive</p>
      </div>
    );
  } else if (props.state === "Success") {
    return (
      <>
        <div
          className={`py-1 px-3 w-min  bg-succes50 flex items-center gap-2 rounded-2xl`}
        >
          <div className={` bg-succes500 w-2 h-2 rounded-full`}></div>
          <p className="text-succes700">Success</p>
        </div>
      </>
    );
  } else if (props.state === "Deactive") {
    return (
      <div
        className={`py-1 px-3 w-min  bg-error50 flex items-center gap-2 rounded-2xl`}
      >
        <div className={` bg-error500 w-2 h-2 rounded-full`}></div>
        <p className="text-[#B42318]">Deactive</p>
      </div>
    );
  } else if (props.state === "Failed") {
    return (
      <div
        className={`py-1 px-3 w-min  bg-error50 flex items-center gap-2 rounded-2xl`}
      >
        <div className={` bg-error500 w-2 h-2 rounded-full`}></div>
        <p className="text-[#B42318]">Failed</p>
      </div>
    );
  } else if (props.state === "Pending") {
    return (
      <div
        className={`py-1 px-3 w-min  bg-gray100 flex items-center gap-2 rounded-2xl `}
      >
        <div className={` bg-gray500 w-2 h-2 rounded-full`}></div>
        <p className="text-gray700">Pending</p>
      </div>
    );
  }
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Main() {
  let accessToken = localStorage.getItem("accessToken");

  const [stateList, setStateList] = useState([]);
  const [message, SetMessage] = useState(null);

  let query = useQuery();
  let sessionId = query.get("session_id"); // استخراج session_id

  useEffect(() => {
    if (sessionId) {
      axios
        .post(
          "https://api.contentor.ca/api/v1/subscription/verify-payment/",
          {
            session_id: sessionId?.replace(/\//g, ""),
          },
          {
            headers: { Authorization: accessToken },
          }
        )
        .then((res) => {
          res.data && SetMessage(res.data.message);
        })
        .catch((err) => {
          if (err.status === 401) {
            localStorage.removeItem("accessToken");
            window.location.href = "/";
          }
        })
        .finally(() => (window.location.href = "/dashboard/billing"));
    }
  }, []);

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");

    const get = async () => {
      try {
        const response = await axios.get(
          "https://api.contentor.ca/api/v1/subscription/payment/",
          {
            headers: { Authorization: accessToken },
          }
        );
        response.data && setStateList(response.data.results);
      } catch (err) {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");

          window.location.href = "/";
        }
      }
    };
    get();
  }, []);

  const by_permium = () => {
    let accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        "https://api.contentor.ca/api/v1/subscription/create-checkout-session/",
        {},
        {
          headers: { Authorization: accessToken },
        }
      )
      .then((res) => window.open(res.data.checkout_url, "_blank"))
      .catch((err) => {
        if (err.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });
  };

  return (
    <>
      <header className=" mb-6 md:p-8 p-4 flex sm:flex-row flex-col gap-3 justify-between">
        <div>
          <p className=" font-bold text-3xl text text-gray900">Billing</p>
          <small className=" flex text-gray600 gap-1 mt-3">
            Here's a report of your subscription payments.
          </small>
        </div>
        {(!stateList[0]?.is_active || stateList.length === 0) && (
          <button
            onClick={by_permium}
            className="h-min text-center w-min   justify-center items-center text-white bg-brand600 px-6 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px] flex gap-2"
          >
            <img src={plusIcon} /> Renewal
          </button>
        )}
      </header>
      {message && (
        <SuccesComponent message={message} onClose={() => SetMessage(null)} />
      )}
      <section className="w-full p-4 overflow-x-auto">
        <div className="w-full border-2 border-gray-200 rounded-xl max-h-[600px] scrollbar-hidden min-w-[1000px]">
          <div className="w-full text-gray-600 flex md:px-6 px-2 py-4 border-b-2 border-gray-200">
            <p className="w-[30%]">Subscriptions Status</p>
            <p className="w-1/5">Remaining Posts</p>
            <p className="w-1/5">Remaining Days</p>
            <p className="w-1/5">Start Date</p>
            <p className="w-1/5">End Date</p>
            <p className="w-1/5">Amount</p>
            <p className="w-1/5">Payment Status</p>
          </div>
          {stateList &&
            stateList.map((item) => {
              const moment = require("moment");
              const end_date = moment(item.end_date);
              const start_date = moment(item.start_date);
              const diffDays = end_date.diff(start_date, "days");

              return (
                <div
                  key={item.id}
                  className="w-full text-gray-600 md:px-6 py-4 flex items-center border-b-2 border-gray-200"
                >
                  <div className="w-[30%]">
                    <Status_component
                      state={
                        item.is_active && !item.is_expired
                          ? "Active"
                          : "Inactive"
                      }
                    />
                    {item.is_expired && (
                      <div className="text-sm mx-2">
                        {
                          (item.inactive_reason = "pwns"
                            ? "Payment was not successful"
                            : (item.inactive_reason = "shex"
                                ? "Subscription has expired"
                                : (item.inactive_reason =
                                    "nrhe" &&
                                    "The number of allowed requests has ended")))
                        }
                      </div>
                    )}
                  </div>
                  <div className="w-1/5">
                    <p>{item.remaining_requests}</p>
                  </div>
                  <div className="w-1/5">
                    <p>{diffDays} Days</p>
                  </div>
                  <div className="w-1/5">
                    <p>{start_date.format("MMM DD, YYYY")}</p>
                  </div>
                  <div className="w-1/5">
                    <p>{end_date.format("MMM DD, YYYY")}</p>
                  </div>
                  <div className="w-1/5">
                    <p>${item.amount} CAD</p>
                  </div>
                  <div className="flex w-1/5">
                    <div className="flex">
                      <Status_component
                        state={item.status === "suc" ? "Success" : ""}
                      />
                      <Status_component
                        state={item.status === "pen" ? "Pending" : ""}
                      />
                      <Status_component
                        state={item.status === "fil" ? "Failed" : ""}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
  // } else {
  //   return (
  //     <>
  //       <div className="w-full md:p-10 p-5">
  //         <div className=" sm:p-0 p-4">
  //           <p className=" font-bold text-3xl text text-gray900">
  //             Access Top-Notch Social Media Content for Only $2!
  //           </p>
  //           <small className="text-gray600">
  //             Get the best deal for crafting social media posts! We are proud to
  //             provide the best rates for social media content. With our
  //             unbeatable rates, enhance your online presence with premium
  //             content for just $2.
  //           </small>
  //         </div>
  //         <div className="w-full flex sm:flex-row flex-col items-center sm:items-stretch justify-center gap-4 mt-24 ">
  //           {card_info.map((item) => {
  //             return (
  //               <div
  //                 className={`${
  //                   item.price === "$2/mo" ? "border-2 border-brand600" : ""
  //                 } py-6 px-4 sm:w-[350px] w-[85%] flex flex-col justify-between items-center border-2  shadow-xl transition duration-500 transform hover:-translate-y-[2px]  rounded-xl gap-10 mb-24`}
  //               >
  //                 <div className="flex flex-col gap-4">
  //                   <p className="font-bold text-4xl text-gray900">
  //                     {item.price}
  //                   </p>
  //                   <p>{item.status}</p>
  //                 </div>
  //                 <div className="w-full flex flex-col gap-3">
  //                   {item.properties.map((property) => {
  //                     return (
  //                       <div className="flex gap-3">
  //                         <img src={checkIcon} />
  //                         <p>{property}</p>
  //                       </div>
  //                     );
  //                   })}
  //                 </div>

  //                 <button
  //                   className={` ${
  //                     item.price !== "$2/mo" ? "bg-gray400" : ""
  //                   } bg-brand600 py-2 w-[80%] text-white rounded-lg`}
  //                 >
  //                   {item.price !== "$2/mo" ? "Curren plan" : "Get premium"}
  //                 </button>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
}

export default Main;
